import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { AppIconLogo } from 'common/app-logos';

type Props = {
  title: string;
  errorMessage: string;
  children: React.ReactNode;
};
const AuthenticationBox = ({ title, errorMessage, children }: Props) => {
  return (
    <Box className={styles.paperBox}>
      <Box className={styles.mainDiv}>
        <Box className={styles.logoBox}>
          <AppIconLogo size="large" border />
        </Box>
        <Typography className={styles.textTitle}>{title}</Typography>
        <Typography className={styles.error}>{errorMessage}</Typography>
        <Box width={'100%'}>{children}</Box>
      </Box>
    </Box>
  );
};

export default AuthenticationBox;
