import { Button } from '@mui/material';
import styles from './styles.module.scss';

type Props = {
  onSendMessage: () => void;
  onGoToSubscription: () => void;
};

const TrialEndedInfo = ({ onSendMessage, onGoToSubscription }: Props) => {
  return (
    <div>
      <h4 className={styles.title}>Zakończył się Twój okres próbny. </h4>
      <p className={styles.description}>
        Zapraszamy do dalszej współpracy. Wystarczy podpiąć kartę, subskrypcja
        jest comiesięczną z możliwością wypowiedzenia jej z okresem 1 miesiąca.
      </p>
      <p className={styles.description}>
        Skontaktuj się z nami, jeżeli PrawMi nie odpowiada Twoim oczekiwaniom,
        postaramy się dla Ciebie przemodelować formę aplikacji.
      </p>
      <div className={styles.buttons}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onGoToSubscription}
        >
          Przejdź do subskrypcji
        </Button>
        <Button variant="outlined" color="secondary" onClick={onSendMessage}>
          Skontaktuj się z nami
        </Button>
      </div>
    </div>
  );
};

export default TrialEndedInfo;
