import { useNavigate, useParams } from 'react-router-dom';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { firestore } from '../../../configs/firebaseConfig';
import ChatFeed from '../../../module-private/components/organisms/chat-feed/ChatFeed';
import DashboardTemplate from '../../../module-private/components/templates/dashboard-template/DashboardTemplate';
import { LawModel } from '../../../models/LawModel';
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import {
  ConversationData,
  UserElement,
} from '../admin-users-page/UserDetailsPanel';
import colors from '../../../configs/colorConfig';
import moment from 'moment';
import { AuthContext } from '../../../context/Auth';
import ListOfSources from 'module-private/components/organisms/list-of-sources/ListOfSources';
import { RulingModel } from 'models/RulingModel';
import { PathType, routesManager } from 'routes/routes';

const AdminConversationPage = () => {
  const { id } = useParams();
  const { userRole } = useContext(AuthContext);
  const [sources, setSources] = useState<LawModel[]>([]);
  const [sourceRulings, setSourceRulings] = useState<RulingModel[]>([]);
  const [users, setUsers] = useState<UserElement[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserElement | null>(null);
  const [selectedConvo, setSelectedConvo] = useState<ConversationData | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [conversations, setConversations] = useState<ConversationData[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingConversations, setLoadingConversations] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setPopoverVisible(!popoverVisible);
  };

  const handleClose = () => {
    setPopoverVisible(false);
    setAnchorEl(null);
  };

  const getUserDetails = async (email: string) => {
    setLoadingConversations(true);
    const customersQuery = query(
      collection(firestore, 'Users'),
      where('email', '==', email)
    );
    const customerSnapshot = await getDocs(customersQuery);
    const userConvoId = customerSnapshot.docs[0]?.id;

    const q = query(
      collection(firestore, 'Conversations'),
      where('uid', '==', userConvoId)
    );

    const querySnapshot = await getDocs(q);
    const conversationsList: ConversationData[] = querySnapshot.docs.map(
      (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          type: 'conversation',
          lastAppended: data.last_appended ? data.last_appended.toDate() : null,
          snippet: data.messages[1].content,
        };
      }
    );

    conversationsList.sort((a, b) => {
      if (a.lastAppended && b.lastAppended) {
        return b.lastAppended.getTime() - a.lastAppended.getTime();
      } else if (a.lastAppended) {
        return -1;
      } else if (b.lastAppended) {
        return 1;
      } else {
        return 0;
      }
    });

    setConversations(conversationsList);
    setLoadingConversations(false);
  };

  useEffect(() => {
    const fetchConversations = async () => {
      setLoadingUsers(true);
      const conversationsQuery = query(
        collection(firestore, 'Conversations'),
        orderBy('last_appended', 'desc')
      );

      const conversationsSnapshot = await getDocs(conversationsQuery);
      const userPromises = conversationsSnapshot.docs.map(
        async (conversationDoc) => {
          const conversationData = conversationDoc.data();
          const userDoc = await getDoc(
            doc(firestore, 'Users', conversationData.uid)
          );

          if (userDoc.exists()) {
            const userData = userDoc.data();
            return {
              id: userDoc.id,
              email: userData.email,
              name: userData.name,
              lastConvo: conversationData.last_appended.toDate(),
            } as UserElement;
          }
        }
      );

      const resolvedUsersData = await Promise.all(userPromises);
      const filteredUsersData = resolvedUsersData.filter(
        (user): user is UserElement => user !== undefined
      );
      const uniqueUsersMap = new Map<string, UserElement>();
      filteredUsersData.forEach((user) => {
        if (!uniqueUsersMap.has(user.id)) {
          uniqueUsersMap.set(user.id, user);
        }
      });

      setLoadingUsers(false);
      setUsers(Array.from(uniqueUsersMap.values()));
    };

    if (id === undefined) {
      fetchConversations();
    }
  }, []);

  useEffect(() => {
    if (selectedUser != null) {
      getUserDetails(selectedUser.email).then((user) => {
        // setSelectedUser(user);
      });
    }
    //eslint-disable-next-line
  }, [selectedUser]);

  useEffect(() => {
    if (userRole !== 1134) {
      navigate(routesManager.getPath(PathType.START_PAGE));
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          right: '0px',
          top: '0px',
          background: colors.primary,
          display: 'flex',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <Box width={'200px'} mr={'10px'} zIndex={1000}>
          {loadingUsers ? (
            <div style={{ padding: '10px' }}> Loading...</div>
          ) : (
            <Select
              size="small"
              fullWidth
              value={selectedUser?.email || ''}
              defaultValue={selectedUser?.email || ''}
            >
              {users.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.email}
                  onClick={() => setSelectedUser(user)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ width: '400px' }}>
                      {user.email}
                    </Typography>
                    <Typography fontSize={'12px'}>
                      {moment(user.lastConvo).format('HH:mm DD.MM.YYYY')}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
        <Box width={'200px'}>
          {loadingConversations ? (
            <div style={{ padding: '10px' }}>Loading...</div>
          ) : (
            <Button
              fullWidth
              onClick={handleButtonClick}
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                textTransform: 'none',
                zIndex: 1000,
              }}
            >
              {moment(selectedConvo?.lastAppended).format('HH:mm DD.MM.YYYY')}
            </Button>
          )}
          <Popover
            open={popoverVisible}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{
              maxHeight: '700px',

              transform: 'translateY(3px)',
            }}
          >
            <List sx={{ padding: '7px' }}>
              {conversations.map((conversation) => (
                <ListItem key={conversation.id} sx={{ padding: '1px' }}>
                  <ListItemButton
                    sx={{
                      backgroundColor:
                        conversation.id === id ? 'lightgray' : '',
                      margin: '0px',
                    }}
                    onClick={() => {
                      setSelectedConvo(conversation);
                      navigate(
                        routesManager.getPath(
                          PathType.MNB_CHAT_ID,
                          conversation.id
                        )
                      );
                    }}
                  >
                    <Box>
                      <Typography>
                        {moment(conversation.lastAppended).format(
                          'HH:mm DD.MM.YYYY'
                        )}
                      </Typography>
                      <Typography
                        fontSize={'12px'}
                        sx={{
                          width: '300px',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: '2',
                        }}
                      >
                        {conversation.snippet}
                      </Typography>
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Popover>
        </Box>
      </div>
      <DashboardTemplate
        leftPanel={
          <ChatFeed
            adminMode
            onSourcesChange={(values: LawModel[], rulings: RulingModel[]) => {
              setSources(values);
              setSourceRulings(rulings);
            }}
            onSourcesLoading={(v: boolean) => {}}
            focusOnInput={false}
            chatId={id ?? undefined}
          />
        }
        rightPanel={
          <div className="rightSidePanel">
            <Box
              id={'boxScrolled'}
              p={'10px'}
              style={{
                height: '100vh',
                overflowY: 'auto',
                display: 'block',
              }}
            >
              <ListOfSources
                sources={sources}
                sourcesLoading={false}
                sourceRulings={sourceRulings}
                onRulingSelect={(source) => {}}
                onLawSelect={(source) => {}}
                expanded={expanded}
                onExpandedChange={(v) => {
                  setExpanded(v);
                }}
              />
            </Box>
          </div>
        }
      />
    </div>
  );
};

export default AdminConversationPage;
