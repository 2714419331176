import { useContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../context/Auth';
import { PathType, routesManager } from 'routes/routes';

const RequireAuth = ({ allowedRoles, allowedUsers }: any) => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);

  return currentUser ? (
    // roles?.find(role => allowedRoles?.includes(role))
    <Outlet />
  ) : (
    <Navigate
      to={routesManager.getPath(PathType.LOGIN)}
      state={{ from: location }}
      replace
    />
  );
  // <Outlet />
};

export default RequireAuth;
