import {
  Box,
  Button,
  Grid,
  GridProps,
  Typography,
  styled,
} from '@mui/material';
import assets from '../../../../assets';
import { RulingModel } from '../../../../models/RulingModel';
import useTextHighlight from '../../../../hooks/useTextHighlight';
import { getCssVariable } from 'styles/getVariables';

const MainGrid = styled(Grid)<GridProps>({});

type Props = {
  source: RulingModel;
  gridProps?: GridProps;
  onSourceSelect: (source: RulingModel) => void;
};
const RulingItem = ({ source, gridProps, onSourceSelect }: Props) => {
  const { blockedText } = useTextHighlight();

  return (
    <MainGrid {...gridProps} container>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{ marginBottom: '0px' }}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Button
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: '700',
                paddingLeft: '0px',
                color: getCssVariable('--text-primary'),
              }}
              onClick={() => {
                onSourceSelect(source);
              }}
            >
              <Typography fontSize={'14px'}>{source.title}</Typography>
              <Typography
                fontSize={'14px'}
                sx={{ fontWeight: '300', paddingLeft: '15px' }}
              >
                {`${
                  source.division ? `${source.division} -` : ''
                } ${source.judgmentDate ? ` ${source.judgmentDate}` : ''}`}
              </Typography>
            </Button>
            <Box
              sx={{
                userSelect: blockedText ? 'none' : 'text',
                padding: '4px',
                ':hover': {
                  cursor: 'pointer',
                  backgroundColor: getCssVariable('--medium-gray'),
                  borderRadius: '50%',
                },
              }}
              onClick={() => {
                onSourceSelect(source);
              }}
              component={'img'}
              src={assets.icons.hammer}
              width="28px"
              height={'28px'}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} display={'flex'}>
        <Typography fontSize={'14px'}>{source.text}</Typography>
      </Grid>
    </MainGrid>
  );
};

export default RulingItem;
