import { Grid, Typography, TypographyProps, styled } from '@mui/material';
import axios from '../../../../api/axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { auth, firestore } from '../../../../configs/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import useSidebar from '../../../../hooks/useSidebar';
import { LawModel, lawFromMap } from '../../../../models/LawModel';
import { RulingModel, rulingFromMap } from '../../../../models/RulingModel';
import useTabs from '../../../../hooks/useTabs';
import {
  getArticlesAsSources,
  getConversationId,
  getRandomRulings,
  getRulingsAsSources,
} from '../../../../api/api-interface';
import styles from './styles.module.scss';
import { getCssVariable } from 'styles/getVariables';
import useConversotion from 'hooks/useConversation';
import SuggestionQuestions from './components/suggestion-questions/SuggestionQuestions';
import { AuthContext } from 'context/Auth';
import Chat from './components/chat/Chat';
import QuestionTextField from '../question-text-field/QuestionTextField';
import { PathType, routesManager } from 'routes/routes';
import { useTranslation } from 'react-i18next';

const TypographySub = styled(Typography)<TypographyProps>({
  fontSize: '14px',
  margin: 'auto',
  fontWeight: '500',
  color: getCssVariable('--primary'),
});

type Props = {
  onSourcesChange: (sources: LawModel[], rulings: RulingModel[]) => void;
  onSourcesLoading: (value: boolean) => void;
  focusOnInput: boolean;
  chatId: string | undefined;
  adminMode?: boolean;
};
const ChatFeed = ({
  onSourcesChange,
  onSourcesLoading,
  focusOnInput,
  chatId,
  adminMode,
}: Props) => {
  const { t } = useTranslation();
  const [currentResponse, setCurrentResponse] = useState<string>('');
  const [loadingResponseText, setLoadingResponseText] = useState<
    string | undefined
  >(undefined);
  const [currentQuestion, setCurrentQuestion] = useState<string>('');
  const [listOfQuestions, setListOfQuestions] = useState<string[]>([]);
  const [listOfResponses, setListOfResponses] = useState<string[]>([]);
  const [showHelperQuestions, setShowHelperQuestions] = useState<number>(0);
  const [conversationId, setConverstionId] = useState<string>('');
  const [responseIsRendering, setResponseIsRendering] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { addToast } = useSidebar();
  const { setDisplayedCase, setRulingList } = useTabs();
  const location = useLocation();
  const [generatingAnswer, setGeneratingAnswer] = useState<boolean>(false);
  const chatStreamResponse = useRef<string>('');
  const { currentUser } = useContext(AuthContext);
  const { setVector } = useConversotion();

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [focusOnInput]);

  useEffect(() => {
    //findUserData();
  }, []);

  useEffect(() => {
    if (chatId) {
      fetchConversation(chatId);
    } else {
      setListOfQuestions([]);
      setListOfResponses([]);
      onSourcesChange([], []);
      setConverstionId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  const handleClickQuestion = async (index: number) => {
    let newSources = await fetchSourcesForConversationAndKey(
      conversationId,
      index + 1
    );

    onSourcesChange(newSources.laws, newSources.rulings);
  };

  const fetchSourcesForConversationAndKey = async (
    id: string,
    key: number
  ): Promise<{ laws: LawModel[]; rulings: RulingModel[] }> => {
    let userConvo: string[] = [];
    let botConvo: string[] = [];
    let laws: LawModel[] = [];
    const sourceRulings: RulingModel[] = [];
    setConverstionId(id);
    const docRef = doc(firestore, 'Conversations', id);
    let l = await getDoc(docRef).then((doc) => {
      let messages = doc.data()?.messages;
      let sourcesMap = doc.data()?.sources;
      let tabs = doc.data()?.tabs ?? [];
      setRulingList(tabs);
      messages?.forEach((message: any, index: number) => {
        if (index % 2 === 0 && index > 0) {
          botConvo.push(message.content);
        } else if (index % 2 === 1) {
          userConvo.push(message.content);
        }
      });
      let sources: LawModel[] = sourcesMap[key.toString()];

      sources?.forEach((source: any) => {
        if (source.act.nr !== -1 && source.act.year !== -1) {
          let rulings: RulingModel[] = [];

          if (source.rulings != null) {
            source.rulings.forEach((ruling: any) => {
              let r: RulingModel = rulingFromMap(ruling);
              rulings.push(r);
            });
          }

          let law: LawModel = lawFromMap(source.act);
          law.rulings = rulings;
          laws.push(law);
        } else {
          if (source?.rulings) {
            source.rulings.forEach((ruling: any) => {
              sourceRulings.push(rulingFromMap(ruling));
            });
          }
        }
      });
      return { laws: laws, rulings: sourceRulings };
    });
    return l;
  };

  const fetchConversation = async (id: string) => {
    let userConvo: string[] = [];
    let botConvo: string[] = [];
    let laws: LawModel[] = [];
    setConverstionId(id);
    const docRef = doc(firestore, 'Conversations', id);

    try {
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        console.error('No conversation found with ID:', id);
        return;
      }

      const data = docSnap.data();
      const messages = data?.messages || [];
      const vector = data?.synthesis_embedding;
      const sourcesMap = data?.sources || {};
      const tabs = data?.tabs || [];

      setVector(vector || '');
      setRulingList(tabs);
      messages.forEach((message: any, index: number) => {
        if (index % 2 === 0 && index > 0) {
          botConvo.push(message.content);
        } else if (index % 2 === 1) {
          userConvo.push(message.content);
        }
      });
      const intKeys = Object.keys(sourcesMap).map(Number);
      const maxKey = intKeys.length > 0 ? Math.max(...intKeys).toString() : '0';
      const sources: LawModel[] = sourcesMap[maxKey] || [];
      const sourceRulings: RulingModel[] = [];
      sources.forEach((source: any) => {
        if (source.act.nr !== -1 && source.act.year !== -1) {
          const rulings: RulingModel[] = [];

          if (source?.rulings) {
            source.rulings.forEach((ruling: any) => {
              rulings.push(rulingFromMap(ruling));
            });
          }

          const law: LawModel = lawFromMap(source.act);
          law.rulings = rulings;
          laws.push(law);
        } else {
          if (source?.rulings) {
            source.rulings.forEach((ruling: any) => {
              sourceRulings.push(rulingFromMap(ruling));
            });
          }
        }
      });

      onSourcesChange(laws, sourceRulings);
      setListOfQuestions(userConvo);
      setListOfResponses(botConvo);
    } catch (error) {
      console.error('Error fetching conversation:', error);
      // Handle error appropriately (e.g., show error message to user)
    }
  };

  const initializeNewQuestion = async (questionText: string) => {
    setDisplayedCase('sources');
    if (!auth.currentUser) return null;

    const token = await auth.currentUser.getIdToken();
    setListOfQuestions((prev) => [...prev, questionText]);
    setResponseIsRendering(true);
    chatStreamResponse.current = '';
    onSourcesLoading(true);
    setIsLoading(true);

    return token;
  };

  const getReplyIdAndNotify = async (token: string, questionText: string) => {
    const replyId = !conversationId
      ? await getConversationId(token, questionText)
      : await getConversationId(token, questionText, conversationId);

    if (!conversationId) {
      addToast({
        message: 'Rozpoczęto nową rozmowę',
        type: 'success',
        id: new Date(),
        title: 'dd',
      });
    }

    return replyId;
  };

  const fetchAndProcessSources = async (token: string, replyId: string) => {
    // Local variables to hold the results as they arrive.
    let sources: LawModel[] = [];
    let randomRulings: RulingModel[] = [];

    // Fire off the random rulings request.
    const randomRulingsPromise = getRandomRulings(replyId, token).then((result) => {
      randomRulings = result;
      // Immediately update the UI with the random rulings
      // (sources might still be empty at this point).
      onSourcesChange(sources, randomRulings);
      return result;
    });

    // Fire off the articles (sources) request.
    const sourcesPromise = getArticlesAsSources(token, replyId).then((result) => {
      sources = result;
      onSourcesLoading(false);
      // Update the UI with the fetched articles and the current random rulings.
      onSourcesChange(sources, randomRulings);
      return result;
    });

    // Wait for the sources to be available.
    const resolvedSources = await sourcesPromise;

    // For each article source, fetch its rulings and update the UI immediately as they resolve.
    const pendingRulings = resolvedSources.map(async (source, index) => {
      const rulings = await getRulingsAsSources(token, replyId, source);
      sources[index].rulings = rulings;
      sources[index].rulingsLoading = false;
      // Update the UI each time a source's rulings are fetched.
      onSourcesChange([...sources], randomRulings);
      return rulings;
    });

    // Wait for all source-specific rulings requests, and ensure random rulings are resolved.
    await Promise.all(pendingRulings);
    await randomRulingsPromise;
  };

  const streamResponse = async (token: string, replyId: string) => {
    const baseUrl = axios.defaults.baseURL?.replace(/\/$/, '') || '';
    const url = `${baseUrl}/interface/stream/${replyId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'text/event-stream',
          'Cache-Control': 'no-cache',
        },
        credentials: 'same-origin',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body!.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      let accumulatedText = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const text = decoder.decode(value, { stream: true });
        buffer += text;

        const lines = buffer.split('\n');
        buffer = lines.pop() || '';
        let thoughts = undefined;
        for (const line of lines) {
          if (line.startsWith('thoughts: ')) {
            thoughts = line.slice(9);
          }
          if (line.startsWith('data: ')) {
            setLoadingResponseText(undefined);
            const chunk = line.slice(6);
            if (chunk === '[DONE]') continue;

            const formattedChunk = chunk.replace(/\\n/g, '\n');
            accumulatedText = formattedChunk;
            setCurrentResponse((prev) => prev + formattedChunk);
            chatStreamResponse.current = accumulatedText;
          }
        }
        if (thoughts) {
          setLoadingResponseText(thoughts);
        }
      }
      if (buffer.startsWith('data: ')) {
        setLoadingResponseText(undefined);
        const chunk = buffer.slice(6);
        if (chunk !== '[DONE]') {
          const formattedChunk = chunk.replace(/\\n/g, '\n');
          accumulatedText = formattedChunk;
          setCurrentResponse((prev) => prev + formattedChunk);
          chatStreamResponse.current = accumulatedText;
        }
      }

      return accumulatedText;
    } catch (error) {
      console.error('Streaming error:', error);
      throw error;
    }
  };

  const handleClick = async (question?: string) => {
    const questionText = question ?? currentQuestion;

    try {
      const token = await initializeNewQuestion(questionText);
      if (!token) {
        throw new Error('Failed to get authentication token');
      }

      const replyId = await getReplyIdAndNotify(token, questionText);
      if (!replyId) {
        throw new Error('Failed to get reply ID');
      }

      await fetchAndProcessSources(token, replyId);

      setGeneratingAnswer(true);
      setIsLoading(false);
      setCurrentResponse('');

      const response = await streamResponse(token, replyId);
      await fetchConversation(replyId);

      setResponseIsRendering(false);
      setCurrentQuestion('');
      setListOfResponses((prev) => [...prev, response]);
      setCurrentResponse('');

      if (listOfResponses.length === 0) {
        navigate(replyId);
      }
    } catch (error) {
      console.error('Error in chat process:', error);
      setIsLoading(false);
      setResponseIsRendering(false);
      setGeneratingAnswer(false);

      addToast({
        message: 'Wystąpił błąd podczas generowania odpowiedzi',
        type: 'error',
        id: new Date(),
        title: 'Błąd',
      });
    }
  };

  useEffect(() => {
    setShowHelperQuestions(showHelperQuestions + 1);
    if (location.pathname === routesManager.getPath(PathType.CHAT)) {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
      setIsLoading(false);
      setCurrentResponse('');
      setResponseIsRendering(false);
      setCurrentQuestion('');
      setGeneratingAnswer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Grid
      container
      pl={1}
      direction="column"
      height={'100%'}
      className="leftSidePanel"
    >
      <Chat
        onQuestionClick={handleClickQuestion}
        listOfQuestions={listOfQuestions}
        listOfResponses={listOfResponses}
        currentResponse={currentResponse}
        loadingResponseText={loadingResponseText}
      />
      <Grid item>
        <Grid container>
          {location.pathname === routesManager.getPath(PathType.CHAT) &&
            isLoading === false &&
            generatingAnswer === false &&
            showHelperQuestions < 2 &&
            listOfQuestions.length === 0 &&
            (currentUser?.activeTrial || currentUser?.activeSubscription) && (
              <SuggestionQuestions onQuestionClick={handleClick} />
            )}

          {currentUser?.activeTrial === false &&
            currentUser?.activeSubscription === false && (
              <div className={styles.infoContainer}>
                <div className={styles.infoTextContainer}>
                  <TypographySub
                    sx={{ color: getCssVariable('--text-primary') }}
                  >
                    {t('dashboard:chat.subscriptionExpiredMessage')}
                  </TypographySub>
                </div>
              </div>
            )}

          <Grid item xs={12} display={'flex'} p={3}>
            <div className={'questionDiv'} style={{ width: '100%' }}>
              {adminMode ? (
                <></>
              ) : (
                <QuestionTextField
                  data-testid="ask-question-button"
                  textfieldProps={{
                    inputRef: textFieldRef,
                  }}
                  disabled={
                    responseIsRendering ||
                    (currentUser?.activeTrial === false &&
                      currentUser?.activeSubscription === false)
                  }
                  onAskQuestion={handleClick}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatFeed;
