import FrenchNavBar from './components/french-nav-bar/FrenchNavBar';
import PolishNavBar from './components/PolishNavBar';

const NavBar = () => {
  return (
    <>
      {process.env.REACT_APP_LANGUAGE !== 'fr' ? (
        <PolishNavBar />
      ) : (
        <FrenchNavBar />
      )}
    </>
  );
};

export default NavBar;
