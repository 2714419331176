import dataPL from '../secrets/firebaseConfig.json';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

let firebaseConfig = dataPL;

if (process.env.REACT_APP_LANGUAGE === 'fr') {
  // Only attempt to load firebaseConfigFR.json locally (development environment)

  if (process.env.REACT_APP_ENV === 'local') {
    const dataFR = require('../secrets/firebaseConfigFR.json');
    firebaseConfig = dataFR;
    try {
      // Dynamically require firebaseConfigFR.json.
      // This line will only be executed in a development environment.
    } catch (error) {
      console.error(
        'firebaseConfigFR.json not found. Falling back to default firebaseConfig.json',
        error
      );
      firebaseConfig = dataPL;
    }
  } else {
    // In production/cloud use the default configuration as firebaseConfigFR.json is not present.
    firebaseConfig = dataPL;
  }
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const provider = new GoogleAuthProvider();

export { auth, provider, firestore };
