import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles.module.scss';
import { useContext, useState, useEffect } from 'react';
import TrialEndingSoonInfo from './TrialEndingSoonInfo';
import { AuthContext } from 'context/Auth';
import TrialEndedInfo from './TrialEndedInfo';
import { useNavigate } from 'react-router-dom';
import { PathType, routesManager } from 'routes/routes';
import { firestore } from '../../../../configs/firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import NewFunctionalityInfo from './NewFunctionalityInfo';
import ReportForm from 'common/layout/sidebar/components/ReportForm';

const Monit = () => {
  const { currentUser } = useContext(AuthContext);
  const [showNewfunctionalityModal, setShowNewfunctionalityModal] =
    useState(true);
  const [showSoonEndingModal, setShowSoonEndingModal] = useState(false);
  const [showEndedModal, setShowEndedModal] = useState(false);
  const [alreadyShownSoonEnding, setAlreadyShownSoonEnding] = useState(true);
  const [daysSinceLastMonit, setDaysSinceLastMonit] = useState(0);
  const [alreadyShownEnded, setAlreadyShownEnded] = useState(true);
  const [alreadyShownNewfunctionality, setAlreadyShownNewfunctionality] =
    useState(true);
  const [daysUnitTrialEnds, setDaysUnitTrialEnds] = useState(0);
  const [updateDaysinFirebase, setUpdateDaysinFirebase] = useState(false);
  const [showReportForm, setShowReportForm] = useState(false);
  const navigate = useNavigate();

  //get info about shown monits from firbase users collection
  const getInfoAboutShownMonits = async () => {
    if (!currentUser?.guid) return;
    const userInfo = await getDoc(doc(firestore, 'Users', currentUser?.guid));

    if (userInfo.data()?.monitDaysSinceLast) {
      const lastMonitDate = new Date(userInfo.data()?.monitDaysSinceLast);
      const daysDelta = Math.ceil(
        (new Date().getTime() - lastMonitDate.getTime()) / (1000 * 60 * 60 * 24)
      );
      setDaysSinceLastMonit(daysDelta);
    } else {
      setUpdateDaysinFirebase(true);
    }

    setAlreadyShownSoonEnding(userInfo.data()?.monitSoonEnding ?? false);
    setAlreadyShownEnded(userInfo.data()?.monitEnded ?? false);
    setAlreadyShownNewfunctionality(userInfo.data()?.newfunctionality ?? false);
  };

  const updateDaysSinceLastMonit = async () => {
    if (!currentUser?.guid) return;
    await setDoc(
      doc(firestore, 'Users', currentUser?.guid),
      { monitDaysSinceLast: new Date().getTime() },
      { merge: true }
    );
  };

  const setShownMonits = async (
    type: 'monitSoonEnding' | 'monitEnded' | 'newfunctionality'
  ) => {
    if (!currentUser?.guid) return;
    await setDoc(
      doc(firestore, 'Users', currentUser?.guid),
      {
        [type]: true,
        monitDaysSinceLast: new Date().getTime(),
      },
      { merge: true }
    );
  };

  useEffect(() => {
    getInfoAboutShownMonits();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (updateDaysinFirebase) {
      updateDaysSinceLastMonit();
      setUpdateDaysinFirebase(false);
    }
    // eslint-disable-next-line
  }, [updateDaysinFirebase]);

  useEffect(() => {
    const calculateDaysUntilTrialEnds = () => {
      if (!currentUser?.trialFinishDate) return 0;

      const trialEndDate = new Date(currentUser.trialFinishDate);
      const today = new Date();

      // Calculate difference in milliseconds and convert to days
      const diffTime = trialEndDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    };

    const daysUntilTrialEnds = calculateDaysUntilTrialEnds();
    setDaysUnitTrialEnds(daysUntilTrialEnds);
  }, [currentUser?.trialFinishDate]);

  useEffect(() => {
    // console.log('daysUnitTrialEnds', daysUnitTrialEnds);
    // if (daysUnitTrialEnds <= 0) {
    //   setShowSoonEndingModal(false);
    //   setShowEndedModal(true);
    //   setShowNewfunctionalityModal(false);
    // } else if (daysUnitTrialEnds <= 4) {
    //   setShowSoonEndingModal(true);
    //   setShowEndedModal(false);
    //   setShowNewfunctionalityModal(false);
    // } else if (daysSinceLastMonit > 10) {
    //   setShowSoonEndingModal(false);
    //   setShowEndedModal(false);
    //   setShowNewfunctionalityModal(true);
    // } else {
    //   setShowSoonEndingModal(false);
    //   setShowEndedModal(false);
    //   setShowNewfunctionalityModal(false);
    // }
  }, [daysUnitTrialEnds, daysSinceLastMonit]);

  const handleCloseModal = (
    chosenType: 'monitSoonEnding' | 'monitEnded' | 'newfunctionality' | null
  ) => {
    console.log('type', chosenType);
    if (chosenType) {
      setShownMonits(chosenType);
    } else {
      if (showEndedModal) {
        setShownMonits('monitEnded');
      } else if (showSoonEndingModal) {
        setShownMonits('monitSoonEnding');
      } else if (showNewfunctionalityModal) {
        setShownMonits('newfunctionality');
      }
    }
    setAlreadyShownSoonEnding(true);
    setAlreadyShownEnded(true);
    setAlreadyShownNewfunctionality(true);
  };

  const handleSendMessage = () => {
    console.log('send message');
    setShowReportForm(true);
    handleCloseModal('monitSoonEnding');
  };

  const handleGoToSubscription = () => {
    navigate(routesManager.getPath(PathType.SUBSCRIPTION));
    handleCloseModal('monitEnded');
  };

  const handleGoToDocuments = () => {
    navigate(routesManager.getPath(PathType.DOCUMENT));
    handleCloseModal('newfunctionality');
  };

  return (
    <Modal
      open={
        (showSoonEndingModal && !alreadyShownSoonEnding) ||
        (showEndedModal && !alreadyShownEnded) ||
        (showNewfunctionalityModal && !alreadyShownNewfunctionality) ||
        showReportForm
        // false
      }
      onClose={() => handleCloseModal(null)}
      aria-labelledby="trial-notification-modal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <div className={styles.container}>
          <div className={styles.closeButton}>
            <IconButton onClick={() => handleCloseModal(null)}>
              <CloseIcon />
            </IconButton>
          </div>
          {showSoonEndingModal && (
            <TrialEndingSoonInfo
              onSendMessage={handleSendMessage}
              onGoToSubscription={handleGoToSubscription}
            />
          )}
          {showEndedModal && (
            <TrialEndedInfo
              onSendMessage={handleSendMessage}
              onGoToSubscription={handleGoToSubscription}
            />
          )}
          {showNewfunctionalityModal && (
            <NewFunctionalityInfo onGoToDocuments={handleGoToDocuments} />
          )}
          {showReportForm && (
            <ReportForm
              onClose={() => setShowReportForm(false)}
              onSend={() => setShowReportForm(false)}
              hideType={true}
              admittedType="question"
            />
          )}
        </div>
        {}
      </Box>
    </Modal>
  );
};

export default Monit;
