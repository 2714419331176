import assets from 'assets';
import styles from './styles.module.scss';

type Props = {
  onClick?: () => void;
  color?: 'light' | 'colorful' | 'dark';
};

const AppFullLogo = ({ onClick, color = 'dark' }: Props) => {
  const getLogoClass = () => {
    switch (color) {
      case 'light':
        return styles.logoTextLight;
      case 'colorful':
        return styles.logoTextColorful;
      case 'dark':
      default:
        return styles.logoTextDark;
    }
  };

  if (process.env.REACT_APP_LANGUAGE !== 'fr') {
    return (
      <div className={styles.fullLogoBox}>
        {onClick ? (
          <button onClick={onClick}>
            <div className={`${styles.logoBox} ${styles['medium']}`}>
              <img src={assets.icons.logo} alt="logo" />
            </div>
          </button>
        ) : (
          <>
            <div className={`${styles.logoBox} ${styles['medium']} `}>
              <img src={assets.icons.logo} alt="logo" />
            </div>
          </>
        )}
        {onClick && (
          <button className={styles.logoButton} onClick={onClick}>
            <h1 className={`${styles.logoText} ${getLogoClass()}`}>PrawMi</h1>
          </button>
        )}
        {!onClick && (
          <h1 className={`${styles.logoText} ${getLogoClass()}`}>PrawMi</h1>
        )}
      </div>
    );
  } else {
    return (
      <>
        {onClick && (
          <button className={styles.logoButton} onClick={onClick}>
            <div className={styles.logoBoxFrench}>
              <h1 className={`${styles.logoTextFrench} ${getLogoClass()}`}>
                LA
              </h1>
              <h1 className={`${styles.logoTextFrench} ${getLogoClass()}`}>
                LOI
              </h1>
              <h1 className={`${styles.logoTextFrench} ${getLogoClass()}`}>
                RE
              </h1>
            </div>
          </button>
        )}
        {!onClick && (
          <div className={styles.logoBoxFrench}>
            <h1 className={`${styles.logoTextFrench} ${getLogoClass()}`}>LA</h1>
            <h1 className={`${styles.logoTextFrench} ${getLogoClass()}`}>
              LOI
            </h1>
            <h1 className={`${styles.logoTextFrench} ${getLogoClass()}`}>RE</h1>
          </div>
        )}
      </>
    );
  }
};

export default AppFullLogo;
