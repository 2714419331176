import styles from './styles.module.scss';

type Props = {
  onClick?: () => void;
};

const AppTextLogo = ({ onClick }: Props) => {
  if (process.env.REACT_APP_LANGUAGE !== 'fr') {
    return (
      <>
        {onClick && (
          <button className={styles.logoButton} onClick={onClick}>
            <h1 className={styles.logoText}>PrawMi</h1>
          </button>
        )}
        {!onClick && <h1 className={styles.logoText}>PrawMi</h1>}
      </>
    );
  } else {
    return (
      <>
        {onClick && (
          <button className={styles.logoButton} onClick={onClick}>
            <div className={styles.logoBoxFrench}>
              <h1 className={styles.logoTextFrench}>LA</h1>
              <h1 className={styles.logoTextFrench}>LOI</h1>
              <h1 className={styles.logoTextFrench}>RE</h1>
            </div>
          </button>
        )}
        {!onClick && (
          <div className={styles.logoBoxFrench}>
            <h1 className={styles.logoTextFrench}>LA</h1>
            <h1 className={styles.logoTextFrench}>LOI</h1>
            <h1 className={styles.logoTextFrench}>RE</h1>
          </div>
        )}
      </>
    );
  }
};

export default AppTextLogo;
