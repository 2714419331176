import styles from './styles.module.scss';

const PricePage = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {' '}
      <div className={styles.policyDiv}>
        <h2>Cennik</h2>
        <div className={styles.statuteContainer}>
          <h4 className={styles.titleH4}>
            Cena produktu to 250 złotych miesięcznie.
          </h4>

          <p></p>
        </div>
      </div>
    </div>
  );
};

export default PricePage;
