import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import RequireAuth from './common/authentication/RequireAuth';
import { AuthProvider } from './context/Auth';
import MainLayout from './common/layout/main-layout/MainLayout';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import useTextHighlight from './hooks/useTextHighlight';
import useDarkMode from 'hooks/useDarkMode';
import { getCssVariable } from 'styles/getVariables';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, firestore } from 'configs/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { routesManager } from './routes/routes';

function App() {
  const { blockedText } = useTextHighlight();
  const { isDarkMode, setUserThemePreference } = useDarkMode();

  if (localStorage.getItem('savedSplitPaneXPos') === null) {
    localStorage.setItem('savedSplitPaneXPos', 'true');
  }

  const findUserData = async () => {
    let userData = null;
    const querySnapshot = await getDocs(
      query(
        collection(firestore, 'Users'),
        where('email', '==', auth.currentUser?.email)
      )
    );
    querySnapshot.forEach((doc) => {
      userData = doc.data();
      if (userData?.theme != null) {
        setUserThemePreference(userData.theme);
      }
    });
  };

  useEffect(() => {
    if (isDarkMode) {
      if (process.env.REACT_APP_LANGUAGE !== 'fr') {
        document.documentElement.setAttribute('data-theme', 'dark-pl');
      } else {
        document.documentElement.setAttribute('data-theme', 'dark-fr');
      }
    } else {
      if (process.env.REACT_APP_LANGUAGE !== 'fr') {
        document.documentElement.setAttribute('data-theme', 'light-pl');
      } else {
        document.documentElement.setAttribute('data-theme', 'light-fr');
      }
    }

    setPrimaryTextColor(getCssVariable('--text-primary'));
    setBorderColor(getCssVariable('--textfield-border'));
    setHoverBorderColor(getCssVariable('--text-secondary'));
    setFocusedColor(getCssVariable('--textfield-border-focused'));
  }, [isDarkMode]);

  useEffect(() => {
    const data = onAuthStateChanged(auth, (user) => {
      if (user?.email) {
        findUserData();
      }
    });

    return () => data();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [primaryTextColor, setPrimaryTextColor] = useState(
    getCssVariable('--text-primary')
  );
  const [borderColor, setBorderColor] = useState(
    getCssVariable('--textfield-border')
  );
  const [hoverBorderColor, setHoverBorderColor] = useState(
    getCssVariable('--text-secondary')
  );
  const [focusedColor, setFocusedColor] = useState(
    getCssVariable('--textfield-border-focused')
  );

  const theme = createTheme({
    typography: {
      fontFamily: 'Lato, sans-serif',
      allVariants: {
        color: primaryTextColor,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            userSelect: blockedText ? 'none' : 'text',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              color: primaryTextColor,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: borderColor,
              },
              '&:hover fieldset': {
                borderColor: hoverBorderColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: focusedColor,
              },
              '&.Mui-disabled fieldset': {
                borderColor: borderColor,
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            color: primaryTextColor,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: primaryTextColor,
            },
            '& .MuiSelect-icon': {
              color: primaryTextColor,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            '&[data-focus="true"]': {
              backgroundColor: getCssVariable('--highlighted-text-background'),
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: '10px',
            backgroundColor: getCssVariable('--popup-background'),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '14px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            userSelect: blockedText ? 'none' : 'text',
          },
        },
      },
    },
    palette: {
      primary: {
        main: getCssVariable('--button-color'),
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route element={<RequireAuth />}>
                {routesManager
                  .getLocalizedRoutes()
                  .filter((route) => route.authorization)
                  .map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<route.element />}
                    />
                  ))}
              </Route>

              {routesManager
                .getLocalizedRoutes()
                .filter((route) => !route.authorization)
                .map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.element />}
                  />
                ))}
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}
export default App;
