import { Box, CircularProgress, Typography } from '@mui/material';
import { LawModel } from '../../../../models/LawModel';
import { RulingModel } from '../../../../models/RulingModel';
import Source from './Source';
import ListOfDistinctRulings from './ListOfDistinctRulings';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  sources: LawModel[];
  sourceRulings: RulingModel[];
  sourcesLoading: boolean;
  onRulingSelect: (source: RulingModel) => void;
  onLawSelect: (source: LawModel) => void;
  expanded: boolean;
  onExpandedChange: (expanded: boolean) => void;
};

const ListOfSources = ({
  sources,
  sourceRulings,
  sourcesLoading,
  onRulingSelect,
  onLawSelect,
  expanded,
  onExpandedChange,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log('sources', sources);
    console.log('sourceRulings', sourceRulings);
  }, [sources, sourceRulings]);

  return (
    <>
      <ListOfDistinctRulings
        expanded={
          expanded ||
          (sources.length === 0 && process.env.REACT_APP_LANGUAGE === 'fr')
        }
        onExpandedChange={onExpandedChange}
        rulings={sourceRulings}
        loading={false}
        onRulingSelect={onRulingSelect}
        onLawSelect={onLawSelect}
      />

      {sources.length > 0 && !sourcesLoading ? (
        <>
          {sources.map((source, _) => (
            <>
              <Source
                source={source}
                onRulingSelect={onRulingSelect}
                onLawSelect={onLawSelect}
              />
            </>
          ))}
        </>
      ) : (
        <>
          {sourcesLoading ? (
            <>
              <Box display={'flex'} justifyContent={'center'}>
                <CircularProgress size={'30px'} />
              </Box>
            </>
          ) : (
            <Box>
              <Box display={'flex'} justifyContent={'center'}>
                {process.env.REACT_APP_LANGUAGE !== 'fr' && (
                  <Typography margin={'40px'} color={'dimgray'}>
                    {t('dashboard:listOfSources.ruling')}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ListOfSources;
