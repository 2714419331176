import { LoginPanel } from '../../components/organisms/authentication';
import Notificator from '../../../common/layout/notificator/Notificator';
import LoginRegistrationTemplate from '../../components/templates/login-registration-template/LoginRegistrationTemplate';

export const LoginPage = () => {
  return (
    <>
      <LoginRegistrationTemplate>
        <LoginPanel />
      </LoginRegistrationTemplate>
      <Notificator />
    </>
  );
};

export default LoginPage;
