import { Grid, Modal, TextFieldProps } from '@mui/material';
import { Button, TextField, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import {
  auth,
  provider,
  firestore,
} from '../../../../../configs/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import AuthenticationBox from '../../../molecules/authentication-box/AuthenticationBox';
import axios from 'axios';
import stripeConfig from '../../../../../secrets/stripeConfig.json';
import { PathType, routesManager } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import { getCssVariable } from 'styles/getVariables';
import styles from './../styles.module.scss';
import ContinuationButton from './ContinuationButton';
import GoogleButton from './GoogleButton';

const FormField = (props: TextFieldProps) => (
  <TextField {...props} margin="normal" required fullWidth>
    {props.children}
  </TextField>
);

const RegistrationPanel = () => {
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [credentials, setCredentials] = useState<{
    login: string;
    password: string;
  }>({ login: '', password: '' });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showNumbers, setShowNumbers] = useState<boolean>(false);
  const [noAccessModal, setNoAccessModal] = useState<boolean>(false);
  const [alreadyExistsMessage, setAlreadyExistsMessage] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCredentialsChange = (
    value: string,
    key: 'login' | 'password'
  ) => {
    setErrorMessage('');
    setCredentials({ ...credentials, [key]: value });
  };

  const doesExist = async (
    userEmail: string,
    collectionName: 'Users' | 'Customers'
  ): Promise<string | null> => {
    try {
      const querySnapshot = await getDocs(
        collection(firestore, collectionName)
      );

      const matchingDocuments = querySnapshot.docs.filter(
        (doc) =>
          doc.data().email.trim().toLowerCase() ===
          userEmail.trim().toLowerCase()
      );

      if (matchingDocuments.length > 0) {
        return matchingDocuments[0].id;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const handleLoginClick = async () => {
    if (validateEmail(credentials.login) == null) {
      setErrorMessage(t('start:registerEmail.invalidEmail'));
      return;
    }
    setIsLoadingCredentials(true);

    let userAlreadyExists = false;
    await doesExist(credentials.login, 'Customers').then((userId) => {
      if (userId) {
        userAlreadyExists = true;
        setAlreadyExistsMessage(true);
      }
    });

    if (!userAlreadyExists) {
      try {
        try {
          console.log(
            'stripeConfig.registrationURL',
            stripeConfig.registrationURL
          );
          console.log(
            'process.env.REACT_APP_FRONTEND_URL',
            `${process.env.REACT_APP_FRONTEND_URL}/dane?cartId=${credentials.login}`
          );
          const response = await axios.post(
            stripeConfig.registrationURL,
            {
              email: credentials.login,
              redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/dane?cartId=${credentials.login}`,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (response.data.success) {
            setShowNumbers(true);
            window.localStorage.setItem(
              'pendingRegistrationEmail',
              credentials.login
            );
          } else {
            setErrorMessage(t('start:registerEmail.emailSendError'));
          }
        } catch (error) {
          console.error('Error:', error);
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }
    setIsLoadingCredentials(false);
  };

  const handleGooleLoginClick = async () => {
    setIsLoadingGoogle(true);
    const response = await signInWithPopup(auth, provider).then((data) => {
      navigate('/dane');
    });
    console.log('response', response);
    setIsLoadingGoogle(false);
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    if (alreadyExistsMessage) {
      setErrorMessage(t('start:registerEmail.emailAlreadyExists'));
    }
    // eslint-disable-next-line
  }, [alreadyExistsMessage]);

  return (
    <AuthenticationBox
      title={t('start:registerEmail.title')}
      errorMessage={errorMessage ? errorMessage : ''}
    >
      <Box>
        {showNumbers ? (
          <>
            <Box display={'flex'} justifyContent={'center'} marginTop={'50px'}>
              <Typography textAlign={'center'}>
                {t('start:registerEmail.thankYou')}
              </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'} marginTop={'20px'}>
              <Typography textAlign={'center'}>
                {t('start:registerEmail.emailSent')}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <FormField
              label="Email"
              value={credentials.login}
              onChange={(e) => handleCredentialsChange(e.target.value, 'login')}
              autoFocus
            />

            <ContinuationButton
              disabled={isLoadingCredentials || isLoadingGoogle}
              isLoading={isLoadingCredentials}
              onClick={handleLoginClick}
              text={t('start:registerEmail.continue')}
            />

            <GoogleButton
              isLoading={isLoadingGoogle}
              onClick={handleGooleLoginClick}
              text={t('start:registerEmail.registerWithGoogle')}
              disabled={isLoadingCredentials || isLoadingGoogle}
            />

            <Grid container>
              <Grid
                item
                xs
                display={'flex'}
                justifyContent={'center'}
                py={'20px'}
              >
                <Typography sx={{ fontSize: '13px' }}>
                  {t('start:registerEmail.alreadyHaveAccount')}
                </Typography>
                <Link
                  to={routesManager.getPath(PathType.LOGIN)}
                  className={styles.customlink}
                >
                  {t('start:registerEmail.login')}
                </Link>
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <Link
                  to={routesManager.getPath(PathType.RODO)}
                  className={`${styles.customlink} ${styles.bottomlink}`}
                >
                  {t('start:registerEmail.privacyPolicy')}
                </Link>
                <hr className={styles.verticalDivider} />
                <Link
                  to={routesManager.getPath(PathType.STATUTE)}
                  className={`${styles.customlink} ${styles.bottomlink}`}
                >
                  {t('start:registerEmail.terms')}
                </Link>
                <hr className={styles.verticalDivider} />
                <Link
                  to={routesManager.getPath(PathType.PRICE)}
                  className={`${styles.customlink} ${styles.bottomlink}`}
                >
                  {t('start:registerEmail.pricing')}
                </Link>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Modal
        open={noAccessModal}
        onClose={() => {
          setNoAccessModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            bgcolor: 'background.paper',
            border: '1px solid #ddd',
            borderRadius: '10px',
            boxShadow: 24,
            p: 2,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: '5px',
            }}
          >
            <p style={{ fontSize: '16px' }}>Witamy!</p>
          </div>
          <p style={{ fontSize: '14px', marginTop: '10px' }}>
            Dziękujemy za chęć skorzystania z PrawMi. Sprzedaż aplikacji jest
            obecnie w fazie beta. By móc się zarejestrować i wziąć udział w
            darmowym okresie testowania proszę skontakuj się wysyłając krótką
            wiadomość na: <strong>biuro@prawmi.pl.</strong>
          </p>
          <p style={{ fontSize: '14px', marginTop: '10px' }}>
            Pozdrawiamy serdecznie
          </p>
          <p style={{ fontSize: '14px', marginTop: '10px' }}>Zespół PrawMi</p>

          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: getCssVariable('--primary') }}
              onClick={() => {
                setNoAccessModal(false);
              }}
              style={{ marginTop: '20px' }}
            >
              Zamknij
            </Button>
          </div>
        </Box>
      </Modal>
    </AuthenticationBox>
  );
};

export default RegistrationPanel;
