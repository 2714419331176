import React, { useEffect, useState } from 'react';
import { ConversationModel } from '../../../../models/ConversationModel';
import { Box } from '@mui/material';
import ConversationButton from './ConversationButton';
import { useTranslation } from 'react-i18next';

type SotrtedConversations = {
  todays: ConversationModel[];
  yesterdays: ConversationModel[];
  thisWeeks: ConversationModel[];
  older: ConversationModel[];
};

const compareDatesByDay = (firstDate: Date, secondDate: Date) => {
  let firstDateApprox = new Date(firstDate);
  firstDateApprox.setHours(0, 0, 0, 0);

  let secondDateAprox = new Date(secondDate);
  secondDateAprox.setHours(0, 0, 0, 0);

  return +firstDateApprox === +secondDateAprox;
};

export const sortConversations = (
  conversations: ConversationModel[]
): SotrtedConversations => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const weekAgo = new Date();
  weekAgo.setDate(weekAgo.getDate() - 7);

  let todays: ConversationModel[] = [];
  let yesterdays: ConversationModel[] = [];
  let thisWeeks: ConversationModel[] = [];
  let older: ConversationModel[] = [];

  conversations.forEach((conversation) => {
    if (compareDatesByDay(conversation.lastAppended, today)) {
      todays.push(conversation);
    } else if (compareDatesByDay(conversation.lastAppended, yesterday)) {
      yesterdays.push(conversation);
    } else if (conversation.lastAppended > weekAgo) {
      thisWeeks.push(conversation);
    } else {
      older.push(conversation);
    }
  });

  return {
    todays: todays,
    yesterdays: yesterdays,
    thisWeeks: thisWeeks,
    older: older,
  };
};

type Props = {
  conversations: ConversationModel[];
  onClick: (conversation: any) => void;
  onDeleteClick: (conversation: any) => void;
};
export const ListOfConversations = ({
  conversations,
  onClick,
  onDeleteClick,
}: Props) => {
  const { t } = useTranslation();

  const [todaysConversations, setTodaysConversations] = useState<
    ConversationModel[]
  >([]);
  const [yesterdaysConversations, setYesterdaysConversations] = useState<
    ConversationModel[]
  >([]);
  const [thisWeeksConversations, setThisWeeksConversations] = useState<
    ConversationModel[]
  >([]);
  const [olderConversations, setOlderConversations] = useState<
    ConversationModel[]
  >([]);

  useEffect(() => {
    let output = sortConversations(conversations);
    setTodaysConversations(output.todays);
    setYesterdaysConversations(output.yesterdays);
    setThisWeeksConversations(output.thisWeeks);
    setOlderConversations(output.older);
    //eslint-disable-next-line
  }, [conversations]);

  const renderConversations = (
    title: string,
    conversations: ConversationModel[]
  ) => {
    if (conversations.length === 0) return null;
    else {
      return (
        <Box>
          <div>
            <label style={{ fontSize: '12px', color: 'gray' }}>{title}</label>
          </div>
          {conversations.map((conversation, index) => {
            return (
              <ConversationButton
                conversation={conversation}
                index={index}
                onDeleteClick={() => {
                  onDeleteClick(conversation);
                }}
                onClick={() => {
                  onClick(conversation);
                }}
              />
            );
          })}
        </Box>
      );
    }
  };

  return (
    <div style={{ padding: '10px' }}>
      <div style={{ height: '24px' }}>
        <p style={{ fontSize: '12px' }}>
          {t('dashboard:sidebar.previousConversations')}
        </p>
      </div>
      {renderConversations(t('dashboard:sidebar.today'), todaysConversations)}
      {renderConversations(
        t('dashboard:sidebar.yesterday'),
        yesterdaysConversations
      )}
      {renderConversations(
        t('dashboard:sidebar.thisWeek'),
        thisWeeksConversations
      )}
      {renderConversations(t('dashboard:sidebar.older'), olderConversations)}
    </div>
  );
};
