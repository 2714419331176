import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import styles from './styles.module.scss';
import Sidebar from 'common/layout/sidebar/Sidebar';
import Monit from 'module-private/components/organisms/monit/Monit';

const DashboardLayout = () => {
  return (
    <Box className={styles.container}>
      <CssBaseline />
      <Box position="absolute" className="sidebar">
        <Sidebar />
      </Box>
      <Box marginLeft={'81px'}>
        <Box className={styles.content} component="main">
          <Outlet />
        </Box>
      </Box>
      <Monit />
    </Box>
  );
};

export default DashboardLayout;
