import { Box, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface UserElement {
  id: string;
  email: string;
  name: string;
  lastConvo?: Date;
}

export interface ConversationData {
  id: string;
  type: 'conversation' | 'document';
  lastAppended: Date | null;
  snippet: string;
}

type Props = {
  user: UserElement;
  conversationsList: ConversationData[];
};

const UserDetailsPanel = ({ user, conversationsList }: Props) => {
  const navigate = useNavigate();

  const [sortedConversations, setSortedConversations] = useState<
    ConversationData[]
  >([]);

  useEffect(() => {
    setSortedConversations(
      conversationsList.sort(
        (a, b) =>
          (b.lastAppended?.getTime() ?? 0) - (a.lastAppended?.getTime() ?? 0)
      )
    );
  }, [conversationsList]);

  return (
    <Box width={'100%'} height={'100%'}>
      <Typography variant="h6">{user.email}</Typography>
      <Box>
        <Box height={'calc(100vh - 130px)'} sx={{ overflow: 'auto' }}>
          {sortedConversations.map((conversation) => (
            <Box>
              <Box
                key={conversation.id}
                width={'100%'}
                padding={'15px'}
                onClick={() => {
                  if (conversation.type === 'conversation') {
                    navigate(`/mnb/czat/${conversation.id}`);
                  } else {
                    navigate(`/mnb/dokumenty/${conversation.id}`);
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: 'lightGray' },
                }}
              >
                <div style={{ display: 'flex', gap: '5px' }}>
                  <Typography>
                    {conversation.type === 'conversation'
                      ? '[KONWERSACJA]'
                      : '[DOKUMENT]'}
                  </Typography>
                  <Typography>
                    {conversation.lastAppended
                      ? format(conversation.lastAppended, 'dd.MM.yyyy HH:mm')
                      : ''}
                  </Typography>
                </div>
                <Typography
                  sx={{
                    maxWidth: '400px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {conversation.snippet}
                </Typography>

                <Typography fontSize={'12px'}>{conversation.id}</Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UserDetailsPanel;
