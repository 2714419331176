import { Button, CircularProgress } from '@mui/material';
import styles from '../styles.module.scss';
import { getCssVariable } from 'styles/getVariables';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  onClick: () => void;
  text: string;
};

const ContinuationButton = ({ disabled, isLoading, onClick, text }: Props) => {
  return (
    <>
      {isLoading ? (
        <div className={styles.buttonLoadingBox}>
          <CircularProgress size={'22px'} sx={{ margin: 'auto' }} />
        </div>
      ) : (
        <Button
          fullWidth
          variant="contained"
          onClick={onClick}
          sx={{
            mt: '16px',
            height: '40px',
            backgroundColor: getCssVariable('--primary'),
          }}
          disabled={disabled}
        >
          {text}
        </Button>
      )}
    </>
  );
};

export default ContinuationButton;
