import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { AppTextLogo } from 'common/app-logos';
import { PathType, routesManager } from 'routes/routes';

const EmptyLayout = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <div className={styles.container}>
        <AppTextLogo
          onClick={() => navigate(routesManager.getPath(PathType.START_PAGE))}
        />
        <Outlet />
      </div>
    </Box>
  );
};

export default EmptyLayout;
