import { Button } from '@mui/material';
import styles from './styles.module.scss';

type Props = {
  onGoToDocuments: () => void;
};

const NewFunctionalityInfo = ({ onGoToDocuments }: Props) => {
  return (
    <div>
      <h4 className={styles.title}>Generuj dokumenty!</h4>
      <p className={styles.description}>
        Nowa opcja w PrawMi. Wpisz wszystkie potrzebne informacje, że
        szczegółami, a aplikacja stworzy dla Ciebie wzór dowolnej umowy.
      </p>

      <div className={styles.buttons}>
        <Button variant="contained" color="secondary" onClick={onGoToDocuments}>
          Przejdź do zakładki dokumentów
        </Button>
      </div>
    </div>
  );
};

export default NewFunctionalityInfo;
