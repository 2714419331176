import StartPageFrench from './start-page-french/StartPageFrench';
import StartPagePolish from './StartPagePolish';

const StartPage = () => {
  if (process.env.REACT_APP_LANGUAGE === 'fr') {
    return <StartPageFrench />;
  } else {
    return <StartPagePolish />;
  }
};

export default StartPage;
