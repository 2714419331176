import { collection, getDocs, query, where } from 'firebase/firestore';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { firestore } from '../../../configs/firebaseConfig';
import UserDetailsPanel, {
  ConversationData,
  UserElement,
} from './UserDetailsPanel';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/Auth';
import { PathType, routesManager } from 'routes/routes';

const AdminUsersPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userRole } = useContext(AuthContext);
  const [loadingConversations, setLoadingConversations] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [users, setUsers] = useState<UserElement[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserElement | null>(null);
  const [searchedText, setSearchedText] = useState('');
  const [conversations, setConversations] = useState<ConversationData[]>([]);
  const [documents, setDocuments] = useState<ConversationData[]>([]);

  const handleUserClick = (user: UserElement) => {
    navigate(routesManager.getPath(PathType.MNB_USERS_ID, user.id));
    setSelectedUser(user);
  };

  useEffect(() => {
    if (userRole !== 1134) {
      navigate(routesManager.getPath(PathType.START_PAGE));
    }
    //eslint-disable-next-line
  }, []);

  const getUsersList = async () => {
    const querySnapshot = await getDocs(query(collection(firestore, 'Users')));

    const usersList: UserElement[] = querySnapshot.docs.map((doc) => {
      return {
        email: doc.data().email,
        id: doc.id,
        name: '',
      };
    });
    let filteredUsers = usersList;
    if (searchedText !== '') {
      filteredUsers = usersList.filter((user) =>
        user.email.toLowerCase().includes(searchedText.toLowerCase())
      );
    }

    setUsers(filteredUsers);
    if (id) {
      setSelectedUser(filteredUsers.find((user) => user.id === id) ?? null);
      getUserConversations(id);
      getUserDocuments(id);
    }
  };

  const getUserConversations = async (id: string) => {
    setLoadingConversations(true);
    const q = query(
      collection(firestore, 'Conversations'),
      where('uid', '==', id)
    );

    const querySnapshot = await getDocs(q);
    const conversationsList: ConversationData[] = querySnapshot.docs.map(
      (doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          type: 'conversation',
          lastAppended: data.last_appended ? data.last_appended.toDate() : null,
          snippet: data.messages[1].content,
        };
      }
    );
    setConversations(conversationsList);
    setLoadingConversations(false);
  };

  const getUserDocuments = async (id: string) => {
    setLoadingDocuments(true);
    const q = query(collection(firestore, 'Documents'), where('uid', '==', id));

    const querySnapshot = await getDocs(q);
    const documentsList: ConversationData[] = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        type: 'document',
        lastAppended: data.last_appended ? data.last_appended.toDate() : null,
        snippet: data.messages[1].content,
      };
    });
    setDocuments(documentsList);
    setLoadingDocuments(false);
  };

  useEffect(() => {
    getUsersList();
    //eslint-disable-next-line
  }, [searchedText]);

  useEffect(() => {
    if (selectedUser != null) {
      getUserConversations(selectedUser.id).then((user) => {
        // setSelectedUser(user);
      });
      getUserDocuments(selectedUser.id).then((user) => {
        // setSelectedUser(user);
      });
    }
    //eslint-disable-next-line
  }, [selectedUser]);

  return (
    <Grid container>
      <Grid
        item
        xs={3}
        sx={{
          maxHeight: 'calc(100vh - 20px)',
          borderRight: '1px solid lightGray',
        }}
      >
        <TextField
          placeholder="szukaj"
          fullWidth
          value={searchedText}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
        />
        <Divider />
        <Box sx={{ overflow: 'auto', height: 'calc(100% - 40px)' }}>
          {users.map((m) => {
            return (
              <>
                <Button
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    textTransform: 'none',
                    justifyContent: 'flex-start',
                    height: '50px',
                    backgroundColor:
                      selectedUser?.email === m.email
                        ? 'lightgray'
                        : 'transparent',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                  onClick={() => handleUserClick(m)}
                >
                  {m.email}
                </Button>
                <Divider />
              </>
            );
          })}
        </Box>
      </Grid>

      <Grid item xs={9} padding={'20px'}>
        {loadingConversations && loadingDocuments ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <>
            {selectedUser != null ? (
              <UserDetailsPanel
                user={selectedUser}
                conversationsList={[...conversations, ...documents]}
              />
            ) : (
              <Typography variant="h6">Wybierz użytkownika</Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminUsersPage;
