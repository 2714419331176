import { useEffect, useRef, useState } from 'react';
import Chat from '../chat-feed/components/chat/Chat';
import { DocumentModel } from 'module-private/models/DocumentModel';
import QuestionTextField from '../question-text-field/QuestionTextField';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../../api/axios';
import { auth } from 'configs/firebaseConfig';
import {
  getDocumentContent,
  getDocumentConversationId,
  getDocumentStatus,
} from 'api/api-interface';
import { StatusEnum } from '../document-display/components/LoadingDocumentPanel';
import { useTranslation } from 'react-i18next';

type Props = {
  chosenDocument: DocumentModel | null;
  onDocumentContentChange: (content: string) => void;
  onConversationStarted: () => void;
  onNewQuestion: () => void;
  onStatusChange: (status: StatusEnum) => void;
};

const ChatFeedDocument = ({
  chosenDocument,
  onDocumentContentChange,
  onConversationStarted,
  onNewQuestion,
  onStatusChange,
}: Props) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const chatStreamResponse = useRef<string>('');
  const [listOfQuestions, setListOfQuestions] = useState<string[]>([]);
  const [listOfResponses, setListOfResponses] = useState<string[]>([]);
  const [currentResponse, setCurrentResponse] = useState<string>('');
  const [conversationId, setConverstionId] = useState<string>('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [responseIsRendering, setResponseIsRendering] =
    useState<boolean>(false);

  const initializeNewQuestion = async (questionText: string) => {
    if (!auth.currentUser) return null;

    const token = await auth.currentUser.getIdToken();
    setListOfQuestions((prev) => [...prev, questionText]);

    return token;
  };

  const getReplyIdAndNotify = async (token: string, questionText: string) => {
    const replyId = !id
      ? await getDocumentConversationId(token, questionText)
      : await getDocumentConversationId(token, questionText, id);
    return replyId;
  };

  const handleAskQuestion = async (questionText: string) => {
    onNewQuestion();
    if (listOfResponses.length < listOfQuestions.length) {
      setListOfResponses((prev) => [...prev, currentResponse]);
    }
    setCurrentResponse('');

    const token = await initializeNewQuestion(questionText);
    if (!token) {
      throw new Error('Failed to get authentication token');
    }

    const replyId = await getReplyIdAndNotify(token, questionText);
    onConversationStarted();

    let localStatus: string = 's1_generation';
    let statusResponseDone: boolean = false;

    while (statusResponseDone !== true) {
      const statusResponse = await getDocumentStatus(token, replyId);
      localStatus = statusResponse.status;
      statusResponseDone = statusResponse.done;
      onStatusChange(localStatus as StatusEnum);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    if (!replyId) {
      throw new Error('Failed to get reply ID');
    }
    console.log('replyId', replyId);
    await getDocumentContent(token, replyId, (chunk) => {
      onDocumentContentChange(chunk);
    });

    await streamResponse(token, replyId);

    if (listOfResponses.length === 0) {
      navigate(replyId);
    }
  };

  useEffect(() => {
    if (chosenDocument) {
      setListOfQuestions(
        chosenDocument?.messages
          .filter((m) => m.role === 'user')
          .map((message) => message.content)
      );
      console.log(
        'chosenDocument',
        chosenDocument.messages.map((message) =>
          message.content.substring(0, 10)
        )
      );
      setListOfResponses(
        chosenDocument.messages
          .filter((m) => m.role === 'assistant')
          .map((message) => message.content)
      );
    }
  }, [chosenDocument]);

  useEffect(() => {
    if (id) {
      //fetchConversation(chatId);
    } else {
      setListOfQuestions([]);
      setListOfResponses([]);
      setConverstionId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const streamResponse = async (token: string, replyId: string) => {
    const baseUrl = axios.defaults.baseURL?.replace(/\/$/, '') || '';
    const url = `${baseUrl}/documents/stream/${replyId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'text/event-stream',
          'Cache-Control': 'no-cache',
        },
        credentials: 'same-origin',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body!.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      let accumulatedText = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const text = decoder.decode(value, { stream: true });
        buffer += text;

        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const chunk = line.slice(6);
            if (chunk === '[DONE]') continue;

            const formattedChunk = chunk.replace(/\\n/g, '\n');
            accumulatedText = formattedChunk;
            setCurrentResponse((prev) => prev + formattedChunk);
            chatStreamResponse.current = accumulatedText;
          }
        }
      }

      // Handle remaining buffer
      if (buffer.startsWith('data: ')) {
        const chunk = buffer.slice(6);
        if (chunk !== '[DONE]') {
          const formattedChunk = chunk.replace(/\\n/g, '\n');
          accumulatedText = formattedChunk;
          setCurrentResponse((prev) => prev + formattedChunk);
          chatStreamResponse.current = accumulatedText;
        }
      }

      return accumulatedText;
    } catch (error) {
      console.error('Streaming error:', error);
      throw error;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        }}
      >
        <p style={{ margin: '10px', width: '100%', textAlign: 'center' }}>
          {t('dashboard:chatFeedDocument.askQuestion')}
          <br />
          {t('dashboard:chatFeedDocument.askQuestion2')}
        </p>
        <Chat
          listOfQuestions={listOfQuestions}
          listOfResponses={listOfResponses}
          currentResponse={currentResponse}
          disableQuestionClick={true}
        />
      </div>
      <div style={{ padding: '20px' }}>
        <QuestionTextField
          data-testid="ask-question-button"
          textfieldProps={{
            inputRef: textFieldRef,
          }}
          disabled={false}
          onAskQuestion={handleAskQuestion}
        />
      </div>
    </div>
  );
};

export default ChatFeedDocument;
