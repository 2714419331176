import React from 'react';
import assets from '../../../../assets';
import styles from './styles.module.scss';

type Props = {
  size?: 'small' | 'medium' | 'large';
  border?: boolean;
  onClick?: () => void;
};

const AppIconLogo = ({ size, border, onClick }: Props) => {
  if (process.env.REACT_APP_LANGUAGE !== 'fr') {
    if (onClick) {
      return (
        <button onClick={onClick} style={{ border: 'none' }}>
          <div
            className={`${styles.logoBox} ${styles[size ?? 'none']} ${
              border ? styles.border : ''
            }`}
          >
            <img src={assets.icons.logo} alt="logo" />
          </div>
        </button>
      );
    } else {
      return (
        <div
          className={`${styles.logoBox} ${styles[size ?? 'none']} ${
            border ? styles.border : styles.borderNone
          }`}
        >
          <img src={assets.icons.logo} alt="logo" />
        </div>
      );
    }
  } else {
    if (onClick) {
      return (
        <button onClick={onClick} style={{ border: 'none' }}>
          <div
            className={`${styles.logoBoxFrench} ${styles[size ?? 'none']} ${
              border ? styles.border : styles.borderNone
            }`}
          >
            <img src={assets.french.logo} alt="logo" />
          </div>
        </button>
      );
    } else {
      return (
        <div
          className={`${styles.logoBoxFrench} ${styles[size ?? 'none']} ${
            border ? styles.border : styles.borderNone
          }`}
        >
          <img src={assets.french.logo} alt="logo" />
        </div>
      );
    }
  }
};

export default AppIconLogo;
