import { Link } from 'react-router-dom';
import { Grid, TextFieldProps, TextField, Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, provider } from '../../../../../configs/firebaseConfig';
import AuthenticationBox from '../../../molecules/authentication-box/AuthenticationBox';
import { routesManager, PathType } from '../../../../../routes/routes';
import { useTranslation } from 'react-i18next';
import styles from './../styles.module.scss';
import ContinuationButton from './ContinuationButton';
import GoogleButton from './GoogleButton';

const FormField = (props: TextFieldProps) => (
  <TextField
    {...props}
    margin="normal"
    required
    fullWidth
    // onChange={(e) => setUsername(e.target.value)}
  >
    {props.children}
  </TextField>
);

const LoginPanel = () => {
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [credentials, setCredentials] = useState<{
    login: string;
    password: string;
  }>({ login: '', password: '' });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCredentialsChange = (
    value: string,
    key: 'login' | 'password'
  ) => {
    setErrorMessage('');
    setCredentials({ ...credentials, [key]: value });
  };

  const handleLoginClick = async () => {
    setIsLoadingCredentials(true);
    await signInWithEmailAndPassword(
      auth,
      credentials.login,
      credentials.password
    )
      .then((data) => {
        navigate(routesManager.getPath(PathType.CHAT));
      })
      .catch((error) => {
        setErrorMessage('Niepoprawne dane logowania');
      });
    setIsLoadingCredentials(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  };

  const handleGooleLoginClick = async () => {
    setIsLoadingGoogle(true);
    await signInWithPopup(auth, provider).then((data) => {
      navigate(routesManager.getPath(PathType.CHAT));
    });
    setIsLoadingGoogle(false);
  };

  return (
    <AuthenticationBox
      title={t('start:login.title')}
      errorMessage={errorMessage}
    >
      <Box>
        <FormField
          label={t('start:login.email')}
          value={credentials.login}
          onChange={(e) => handleCredentialsChange(e.target.value, 'login')}
          autoFocus
        />
        <FormField
          label={t('start:login.password')}
          value={credentials.password}
          onChange={(e) => handleCredentialsChange(e.target.value, 'password')}
          autoComplete="current-password"
          onKeyDown={handleKeyDown}
          type="password"
        />

        <ContinuationButton
          disabled={isLoadingCredentials || isLoadingGoogle}
          isLoading={isLoadingCredentials}
          onClick={handleLoginClick}
          text={t('start:login.login')}
        />

        <GoogleButton
          isLoading={isLoadingGoogle}
          onClick={handleGooleLoginClick}
          text={t('start:login.google')}
          disabled={isLoadingCredentials || isLoadingGoogle}
        />

        <Grid container>
          <Grid item xs display={'flex'} justifyContent={'center'} py={'20px'}>
            <p style={{ fontSize: '13px' }}>{t('start:login.noAccount')}</p>
            <Link
              to={routesManager.getPath(PathType.REGISTRATION)}
              className={styles.customlink}
            >
              {t('start:login.register')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthenticationBox>
  );
};

export default LoginPanel;
