import { useEffect, useState } from 'react';
import LoadingRow from './LoadingRow';
import { useTranslation } from 'react-i18next';

type Porps = {
  status: StatusEnum | null;
};

export enum StatusEnum {
  S1_GENERATION = 's1_generation',
  S2_1_VALIDATION = 's2_1_validation',
  S2_2_VALIDATION = 's2_2_validation',
  S2_3_VALIDATION = 's2_3_validation',
  S3_AGGREGATION = 's3_aggregation',
}

const LoadingDocumentPanel = ({ status }: Porps) => {
  const [listOfDone, setListOfDone] = useState<StatusEnum[]>([]);
  const { t } = useTranslation();

  const texts = {
    [StatusEnum.S1_GENERATION]: t(
      'dashboard:loadingDocumentPanel.s1Generation'
    ),
    [StatusEnum.S2_1_VALIDATION]: t(
      'dashboard:loadingDocumentPanel.s21Validation'
    ),
    [StatusEnum.S2_2_VALIDATION]: t(
      'dashboard:loadingDocumentPanel.s22Validation'
    ),
    [StatusEnum.S2_3_VALIDATION]: t(
      'dashboard:loadingDocumentPanel.s23Validation'
    ),
    [StatusEnum.S3_AGGREGATION]: t(
      'dashboard:loadingDocumentPanel.s3Aggregation'
    ),
  };

  const handleDone = (currentStatus: StatusEnum) => {
    const statusOrder = Object.values(StatusEnum);
    const currentIndex = statusOrder.indexOf(currentStatus);
    const completedStatuses = statusOrder.slice(0, currentIndex + 1);
    setListOfDone(completedStatuses);
  };

  useEffect(() => {
    if (status) {
      handleDone(status);
    }
  }, [status]);

  return (
    <div>
      {Object.entries(texts).map(([key, value]) => (
        <LoadingRow
          key={key}
          text={value}
          done={listOfDone.includes(key as StatusEnum) && key !== status}
          loading={key === status}
        />
      ))}
    </div>
  );
};

export default LoadingDocumentPanel;
