import { Button, CircularProgress } from '@mui/material';
import styles from '../styles.module.scss';
import assets from '../../../../../assets';

type Props = {
  isLoading: boolean;
  onClick: () => void;
  text: string;
  disabled: boolean;
};

const GoogleButton = ({ isLoading, onClick, text, disabled }: Props) => {
  return (
    <>
      {isLoading ? (
        <div className={styles.buttonLoadingBox}>
          <CircularProgress size={'22px'} sx={{ margin: 'auto' }} />
        </div>
      ) : (
        <Button
          fullWidth
          variant="contained"
          onClick={onClick}
          sx={{
            mt: 3,
            backgroundColor: 'white',
            height: '40px',
            boxShadow: '0px 0px 0px 0px',
            border: '1px solid black',
            color: 'black',
            '&:hover': {
              backgroundColor: 'white',
            },
          }}
          disabled={disabled}
          startIcon={
            <img
              alt={'googleIcon'}
              src={assets.icons.googleIcon}
              style={{ width: '20px', height: '20px' }}
            />
          }
        >
          {text}
        </Button>
      )}
    </>
  );
};

export default GoogleButton;
